var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "phylo_tree_dropdown" } }, [
    _c("div", { staticClass: "left-sidebar" }, [
      _c(
        "div",
        {
          staticClass: "btn-group btn-group-toggle",
          attrs: { id: "tree_type", "data-toggle": "buttons" },
        },
        [
          _c(
            "label",
            {
              staticClass: "btn btn-outline-dark",
              staticStyle: {
                margin: "0 1% 0 0",
                width: "100%",
                "z-index": "0",
              },
              attrs: { for: "orthologs" },
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.type_tree,
                    expression: "type_tree",
                  },
                ],
                attrs: {
                  type: "radio",
                  id: "orthologs",
                  value: "orth",
                  checked: "",
                },
                domProps: { checked: _vm._q(_vm.type_tree, "orth") },
                on: {
                  input: function ($event) {
                    return _vm.cleanTreeOpts()
                  },
                  change: function ($event) {
                    _vm.type_tree = "orth"
                  },
                },
              }),
              _vm._v("\n                RiboVision\n            "),
            ]
          ),
          _vm._v(" "),
          _c(
            "label",
            {
              staticClass: "btn btn-outline-dark",
              staticStyle: { margin: "0 0 0 1%", width: "50%", "z-index": "0" },
              attrs: { for: "upload" },
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.type_tree,
                    expression: "type_tree",
                  },
                ],
                attrs: { type: "radio", id: "upload", value: "upload" },
                domProps: { checked: _vm._q(_vm.type_tree, "upload") },
                on: {
                  input: function ($event) {
                    return _vm.cleanTreeOpts()
                  },
                  change: function ($event) {
                    _vm.type_tree = "upload"
                  },
                },
              }),
              _vm._v("\n                User upload\n            "),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      (_vm.type_tree == "para") | (_vm.type_tree == "orth")
        ? _c(
            "div",
            { attrs: { id: "treeselect" } },
            [
              _c(
                "treeselect",
                {
                  ref: "treeselect",
                  attrs: {
                    "load-options": _vm.loadOptions,
                    placeholder: "Select a phylogenetic group",
                    "no-children-text": "Loading... or no children",
                    multiple: true,
                    options: _vm.options,
                    flat: true,
                    limit: 3,
                    "default-expand-level": 1,
                  },
                  on: {
                    input: function ($event) {
                      return _vm.loadProteinTypes(_vm.tax_id, _vm.type_tree)
                    },
                  },
                  model: {
                    value: _vm.tax_id,
                    callback: function ($$v) {
                      _vm.tax_id = $$v
                    },
                    expression: "tax_id",
                  },
                },
                [
                  _vm._v("Loading phylogenetic tree "),
                  _c("img", {
                    staticStyle: { height: "25px" },
                    attrs: {
                      src: "static/img/loading.gif",
                      alt: "Loading phylogenetic tree",
                    },
                  }),
                ]
              ),
            ],
            1
          )
        : _c("div", [
            _vm._v("\n            Select an alignment file:\n            "),
            _c("p", [
              _c("input", {
                ref: "custom_aln_file",
                staticClass: "btn btn-outline-dark",
                attrs: {
                  id: "inputUploadFasta",
                  type: "file",
                  accept: ".fasta,.fas,.fa",
                },
                on: {
                  change: function ($event) {
                    return _vm.handleFileUpload()
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm.file && _vm.type_tree == "upload"
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-outline-dark",
                      attrs: { id: "uploadShowFasta" },
                      on: {
                        click: function ($event) {
                          return _vm.submitCustomAlignment()
                        },
                      },
                    },
                    [_vm._v("Upload the alignment")]
                  )
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("p", [
              !_vm.file && _vm.type_tree == "upload"
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-outline-dark",
                      attrs: { id: "downloadExampleFasta" },
                      on: {
                        click: function ($event) {
                          return _vm.getExampleFile(
                            "static/alignments/EFTU_example.fas",
                            "PVExampleAlignment.fas"
                          )
                        },
                      },
                    },
                    [_vm._v("Download example alignment")]
                  )
                : _vm._e(),
            ]),
          ]),
      _vm._v(" "),
      _c("p", [
        _vm.tax_id
          ? _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.protein_type_obj,
                    expression: "protein_type_obj",
                  },
                ],
                staticClass: "btn btn-outline-dark dropdown-toggle",
                staticStyle: { margin: "0 1% 0 0", width: "100%" },
                attrs: { id: "select_protein_type" },
                on: {
                  change: [
                    function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.protein_type_obj = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                    function ($event) {
                      return _vm.loadData(_vm.tax_id, _vm.type_tree)
                    },
                  ],
                },
              },
              [
                _c(
                  "option",
                  {
                    attrs: { selected: "", disabled: "", hidden: "" },
                    domProps: { value: null },
                  },
                  [_vm._v("Select RNA type")]
                ),
                _vm._v(" "),
                _vm._l(_vm.proteinTypes, function (proteinType) {
                  return _c("option", { key: proteinType }, [
                    _vm._v(_vm._s(proteinType)),
                  ])
                }),
              ],
              2
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm.protein_type_obj && _vm.tax_id
          ? _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.alnobj,
                    expression: "alnobj",
                  },
                ],
                staticClass: "btn btn-outline-dark dropdown-toggle",
                attrs: { id: "selectaln" },
                on: {
                  change: [
                    function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.alnobj = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                    function ($event) {
                      return _vm.handleAlignChange()
                    },
                  ],
                },
              },
              [
                _c(
                  "option",
                  {
                    attrs: { selected: "", disabled: "", hidden: "" },
                    domProps: { value: null },
                  },
                  [_vm._v("Select an alignment")]
                ),
                _vm._v(" "),
                _vm._l(_vm.alignments, function (aln) {
                  return _c(
                    "option",
                    {
                      key: aln.txt,
                      domProps: { value: { id: aln.value, text: aln.text } },
                    },
                    [_vm._v(_vm._s(aln.text))]
                  )
                }),
              ],
              2
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm.alnobj &&
      _vm.alnobj == "custom" &&
      _vm.file &&
      _vm.type_tree == "upload"
        ? _c("div", [
            _c("b", [_vm._v("Select a format for 3D structure:")]),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.cifPdbMode,
                  expression: "cifPdbMode",
                },
              ],
              attrs: {
                type: "radio",
                id: "radioCIF",
                name: "CIF/PDB mode",
                value: "CIF",
              },
              domProps: { checked: _vm._q(_vm.cifPdbMode, "CIF") },
              on: {
                change: function ($event) {
                  _vm.cifPdbMode = "CIF"
                },
              },
            }),
            _vm._v("CIF"),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.cifPdbMode,
                  expression: "cifPdbMode",
                },
              ],
              attrs: {
                type: "radio",
                id: "radioPDB",
                name: "CIF/PDB mode",
                value: "PDB",
              },
              domProps: { checked: _vm._q(_vm.cifPdbMode, "PDB") },
              on: {
                change: function ($event) {
                  _vm.cifPdbMode = "PDB"
                },
              },
            }),
            _vm._v("PDB"),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _vm.cifPdbMode == "CIF"
              ? _c("div", [
                  _vm._v(
                    "\n\n                Upload a custom CIF:\n                "
                  ),
                  _c("br"),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass: "btn btn-outline-dark",
                      staticStyle: { margin: "0 1% 0 0", width: "100%" },
                      attrs: { for: "uploadCustomCIF", id: "cif-upload" },
                    },
                    [_vm._v("Choose File")]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    ref: "customCIFfile",
                    staticClass: "btn btn-outline-dark",
                    staticStyle: { margin: "0 1% 0 0", width: "100%" },
                    attrs: {
                      id: "uploadCustomCIF",
                      type: "file",
                      accept: ".cif",
                    },
                    on: {
                      change: function ($event) {
                        _vm.cifFileUploadedFlag = true
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm.cifFileUploadedFlag
                    ? _c("div", [
                        _c(
                          "label",
                          {
                            staticStyle: { margin: "0 1% 0 0", width: "100%" },
                            attrs: { for: "provideEntityID" },
                          },
                          [
                            _vm._v(
                              "Provide an Entity ID for a\n                        desired RNA chain"
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          ref: "entity_id",
                          staticClass: "btn btn-outline-dark",
                          staticStyle: { margin: "0 1% 0 0", width: "100%" },
                          attrs: {
                            id: "provideEntityID",
                            type: "number",
                            placeholder: "Entity ID",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.uploadCustomCIF()
                            },
                          },
                        }),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.cifPdbMode == "PDB"
              ? _c("div", [
                  _vm._v(
                    "\n                Upload a custom PDB:\n                "
                  ),
                  _c("br"),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass: "btn btn-outline-dark",
                      attrs: { for: "uploadCustomPDB", id: "pdb-upload" },
                    },
                    [_vm._v("Choose File")]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    ref: "customPDBfile",
                    staticClass: "btn btn-outline-dark",
                    attrs: {
                      id: "uploadCustomPDB",
                      type: "file",
                      accept: ".pdb",
                    },
                    on: {
                      change: function ($event) {
                        _vm.pdbFileUploadedFlag = true
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm.pdbFileUploadedFlag
                    ? _c("div", [
                        _vm._v(
                          "\n                    Upload the matching RNA sequence:\n                    "
                        ),
                        _c("br"),
                        _vm._v(" "),
                        _c(
                          "label",
                          {
                            staticClass: "btn btn-outline-dark",
                            staticStyle: { margin: "0 1% 0 0", width: "100%" },
                            attrs: {
                              for: "uploadCustomFullSequence",
                              id: "full-sequence-upload",
                            },
                          },
                          [_vm._v("Choose File")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          ref: "customFullSequence",
                          staticClass: "btn btn-outline-dark",
                          staticStyle: { margin: "0 1% 0 0", width: "100%" },
                          attrs: {
                            id: "uploadCustomFullSequence",
                            type: "file",
                            accept: ".fas,.fasta,.fa",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.uploadCustomFullSequence()
                            },
                          },
                        }),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "p",
        [
          _vm.alnobj && _vm.alnobj != "custom"
            ? _c("span", [_vm._v("Select/type PDB entry:")])
            : _vm._e(),
          _vm._v(" "),
          _vm.alnobj && _vm.alnobj != "custom"
            ? _c("autocomplete", {
                attrs: { id: "pdb_input", "isAsync:true": "", items: _vm.pdbs },
                model: {
                  value: _vm.pdbid,
                  callback: function ($$v) {
                    _vm.pdbid = $$v
                  },
                  expression: "pdbid",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("p", [
        (_vm.alnobj && _vm.chains && _vm.fasta_data && _vm.pdbid) ||
        _vm.uploadSession
          ? _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.chainid,
                    expression: "chainid",
                  },
                ],
                staticClass: "form-control btn-outline-dark",
                style: { resize: "both" },
                attrs: { multiple: "", id: "polymerSelect" },
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.chainid = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                },
              },
              [
                _c(
                  "option",
                  {
                    attrs: { selected: "", disabled: "" },
                    domProps: { value: null },
                  },
                  [_vm._v("Matching RNA chain to visualize:")]
                ),
                _vm._v(" "),
                _vm._l(_vm.chains, function (chain) {
                  return _c(
                    "option",
                    {
                      key: chain.value,
                      domProps: { value: chain.value },
                      on: {
                        click: function ($event) {
                          _vm.selectedProteins = []
                          _vm.postStructureData(_vm.pdbid, _vm.chainid)
                          _vm.calculateProteinContacts(_vm.pdbid, _vm.chainid)
                          _vm.populateECODranges(_vm.pdbid, _vm.chainid)
                          _vm.showPDBViewer(
                            _vm.pdbid,
                            _vm.chainid,
                            _vm.guideOff ? chain.entityID : _vm.RVGuideEntityId
                          )
                        },
                      },
                    },
                    [_vm._v("\n                    " + _vm._s(chain.text))]
                  )
                }),
              ],
              2
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm.structure_mapping && _vm.chains && !_vm.pdbcust && !_vm.cifcust
        ? _c("div", [
            _vm.topology_loaded
              ? _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.downloadMapDataOpt,
                        expression: "downloadMapDataOpt",
                      },
                    ],
                    staticClass: "btn btn-outline-dark dropdown-toggle",
                    attrs: { id: "downloadDataBtn" },
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.downloadMapDataOpt = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                    },
                  },
                  [
                    _c(
                      "option",
                      {
                        attrs: { selected: "", disabled: "" },
                        domProps: { value: null },
                      },
                      [_vm._v("Download mapped data")]
                    ),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "csv" } }, [
                      _vm._v("As CSV file"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "pymol" } }, [
                      _vm._v("As PyMOL script"),
                    ]),
                  ]
                )
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.structure_mapping && (_vm.pdbcust || _vm.cifcust)
        ? _c("div", [
            _vm.topology_loaded
              ? _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.downloadMapDataOpt,
                        expression: "downloadMapDataOpt",
                      },
                    ],
                    staticClass: "btn btn-outline-dark dropdown-toggle",
                    attrs: { id: "downloadDataBtn" },
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.downloadMapDataOpt = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                    },
                  },
                  [
                    _c(
                      "option",
                      {
                        attrs: { selected: "", disabled: "" },
                        domProps: { value: null },
                      },
                      [_vm._v("Download mapped data")]
                    ),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "csv" } }, [
                      _vm._v("As CSV file"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "pymol_custom" } }, [
                      _vm._v("As PyMOL script"),
                    ]),
                  ]
                )
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("p"),
      _vm.topology_loaded && _vm.type_tree == "orth"
        ? _c(
            "div",
            { staticClass: "checkbox", attrs: { id: "showRNAcontext" } },
            [
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.checkedRNA,
                      expression: "checkedRNA",
                    },
                  ],
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.checkedRNA)
                      ? _vm._i(_vm.checkedRNA, null) > -1
                      : _vm.checkedRNA,
                  },
                  on: {
                    change: [
                      function ($event) {
                        var $$a = _vm.checkedRNA,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.checkedRNA = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.checkedRNA = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.checkedRNA = $$c
                        }
                      },
                      function ($event) {
                        return _vm.updateMolStarWithRibosome(_vm.checkedRNA)
                      },
                    ],
                  },
                }),
                _vm._v("\n                Show ribosomal context in 3D"),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("p"),
      _vm._v(" "),
      _vm.modified && _vm.cifcust && _vm.structure_mapping
        ? _c("div", [
            _c(
              "form",
              {
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.submitModificationsCustom.apply(null, arguments)
                  },
                },
              },
              [
                _c("label", [_vm._v("Select modified residues to highlight")]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "selection-box",
                    style: {
                      backgroundColor: "white",
                      padding: "10px",
                      border: "1px solid #ddd",
                      marginBottom: "10px",
                      maxHeight: "100px",
                      overflowY: "auto",
                    },
                    attrs: { id: "modSelectCustom" },
                  },
                  [
                    _c("div", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selectAllModifiedCustomChecked,
                            expression: "selectAllModifiedCustomChecked",
                          },
                        ],
                        attrs: {
                          type: "checkbox",
                          id: "selectAllModifiedCustom",
                        },
                        domProps: {
                          checked: Array.isArray(
                            _vm.selectAllModifiedCustomChecked
                          )
                            ? _vm._i(_vm.selectAllModifiedCustomChecked, null) >
                              -1
                            : _vm.selectAllModifiedCustomChecked,
                        },
                        on: {
                          change: [
                            function ($event) {
                              var $$a = _vm.selectAllModifiedCustomChecked,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.selectAllModifiedCustomChecked =
                                      $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.selectAllModifiedCustomChecked = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.selectAllModifiedCustomChecked = $$c
                              }
                            },
                            _vm.selectAllModifiedCustomChanged,
                          ],
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        { attrs: { for: "selectAllModifiedCustom" } },
                        [_vm._v("Select All")]
                      ),
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.modified_residues.entries(), function (ref) {
                      var text = ref[0]
                      var k = ref[1]
                      return _c("div", { key: k }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.selectedResiduesCustom,
                              expression: "selectedResiduesCustom",
                            },
                          ],
                          attrs: { type: "checkbox", id: text },
                          domProps: {
                            value: text,
                            checked: Array.isArray(_vm.selectedResiduesCustom)
                              ? _vm._i(_vm.selectedResiduesCustom, text) > -1
                              : _vm.selectedResiduesCustom,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.selectedResiduesCustom,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = text,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.selectedResiduesCustom = $$a.concat([
                                      $$v,
                                    ]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.selectedResiduesCustom = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.selectedResiduesCustom = $$c
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("label", { attrs: { for: text } }, [
                          _vm._v(_vm._s(text)),
                        ]),
                      ])
                    }),
                  ],
                  2
                ),
                _vm._v(" "),
                _c("button", { attrs: { type: "submit" } }, [
                  _vm._v("Submit Residues"),
                ]),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.topology_loaded && !_vm.checkedRNA
        ? _c("div", [
            _c("div", { attrs: { id: "maskingSection" } }, [
              _c("p"),
              _c("div", { staticClass: "checkbox" }, [
                _c("label", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.checked_filter,
                        expression: "checked_filter",
                      },
                    ],
                    attrs: { type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.checked_filter)
                        ? _vm._i(_vm.checked_filter, null) > -1
                        : _vm.checked_filter,
                    },
                    on: {
                      change: [
                        function ($event) {
                          var $$a = _vm.checked_filter,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                (_vm.checked_filter = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.checked_filter = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.checked_filter = $$c
                          }
                        },
                        function ($event) {
                          return _vm.cleanFilter(
                            _vm.checked_filter,
                            _vm.masking_range
                          )
                        },
                      ],
                    },
                  }),
                  _vm._v("\n                        Highlight region"),
                ]),
              ]),
              _vm._v(" "),
              _vm.checked_filter
                ? _c("span", [
                    _c("b", [_vm._v("Input multiple")]),
                    _vm._v(" residue ranges to "),
                    _c("b", [_vm._v("show")]),
                    _vm._v(", separated by\n                    semicolon. "),
                    _c("br"),
                    _vm._v(" For example: 1-80;91-111;"),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.checked_filter
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.masking_range,
                        expression: "masking_range",
                      },
                    ],
                    staticClass: "input-group-text",
                    domProps: { value: _vm.masking_range },
                    on: {
                      input: [
                        function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.masking_range = $event.target.value
                        },
                        function ($event) {
                          return _vm.handleMaskingRanges(_vm.masking_range)
                        },
                      ],
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("p"),
            ]),
            _vm._v(" "),
            _vm.correct_mask != true && _vm.masking_range != null
              ? _c("p", [_vm._v("Incorrect range syntax!")])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { attrs: { id: "customDataSection" } }, [
              _c("p"),
              _c("div", { staticClass: "checkbox" }, [
                _c("label", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.checked_customMap,
                        expression: "checked_customMap",
                      },
                    ],
                    attrs: { type: "checkbox", id: "uploadCustomData" },
                    domProps: {
                      checked: Array.isArray(_vm.checked_customMap)
                        ? _vm._i(_vm.checked_customMap, null) > -1
                        : _vm.checked_customMap,
                    },
                    on: {
                      change: [
                        function ($event) {
                          var $$a = _vm.checked_customMap,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                (_vm.checked_customMap = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.checked_customMap = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.checked_customMap = $$c
                          }
                        },
                        function ($event) {
                          return _vm.cleanCustomMap(_vm.checked_customMap)
                        },
                      ],
                    },
                  }),
                  _vm._v(
                    "\n                        Upload custom mapping data"
                  ),
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm.checked_customMap
                    ? _c("input", {
                        ref: "custom_csv_file",
                        staticClass: "btn btn-outline-dark",
                        attrs: {
                          id: "inputUploadCSV",
                          type: "file",
                          accept: ".csv",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.handleCustomMappingData()
                          },
                        },
                      })
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _vm.raiseCustomCSVWarn
                  ? _c("p", {
                      domProps: { innerHTML: _vm._s(_vm.raiseCustomCSVWarn) },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("p", [
                  _vm.checked_customMap && _vm.csv_data == null
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-outline-dark",
                          attrs: { id: "downloadExampleCSV", type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.getExampleFile(
                                "static/alignments/rv3_example_cusom_mapping.csv",
                                "RVExampleCustomMapping.csv"
                              )
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                            Download example mapping data\n                        "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
              ]),
              _vm._v(" "),
              _c("p"),
            ]),
            _vm._v(" "),
            _vm.topology_loaded && _vm.protein_contacts
              ? _c("div", [
                  _c(
                    "form",
                    {
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.submitProteins.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("label", [
                        _vm._v("Select RNA-protein contacts to view in 3D"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "selection-box",
                          style: {
                            backgroundColor: "white",
                            padding: "10px",
                            border: "1px solid #ddd",
                            marginBottom: "10px",
                            maxHeight: "100px",
                            overflowY: "auto",
                          },
                          attrs: { id: "proteinSelect" },
                        },
                        [
                          _c("div", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.selectAllProteinsChecked,
                                  expression: "selectAllProteinsChecked",
                                },
                              ],
                              attrs: {
                                type: "checkbox",
                                id: "selectAllProteins",
                              },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.selectAllProteinsChecked
                                )
                                  ? _vm._i(_vm.selectAllProteinsChecked, null) >
                                    -1
                                  : _vm.selectAllProteinsChecked,
                              },
                              on: {
                                change: [
                                  function ($event) {
                                    var $$a = _vm.selectAllProteinsChecked,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.selectAllProteinsChecked =
                                            $$a.concat([$$v]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.selectAllProteinsChecked = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.selectAllProteinsChecked = $$c
                                    }
                                  },
                                  _vm.selectAllProteinsChanged,
                                ],
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              { attrs: { for: "selectAllProteins" } },
                              [_vm._v("Select All")]
                            ),
                          ]),
                          _vm._v(" "),
                          _vm._l(_vm.protein_chains, function (chain) {
                            return _c("div", { key: chain.key }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.selectedProteins,
                                    expression: "selectedProteins",
                                  },
                                ],
                                attrs: { type: "checkbox", id: chain.value },
                                domProps: {
                                  value: chain.value,
                                  checked: Array.isArray(_vm.selectedProteins)
                                    ? _vm._i(
                                        _vm.selectedProteins,
                                        chain.value
                                      ) > -1
                                    : _vm.selectedProteins,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a = _vm.selectedProteins,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = chain.value,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.selectedProteins = $$a.concat([
                                            $$v,
                                          ]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.selectedProteins = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.selectedProteins = $$c
                                    }
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("label", { attrs: { for: chain.value } }, [
                                _vm._v(_vm._s(chain.banname)),
                              ]),
                            ])
                          }),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c("button", { attrs: { type: "submit" } }, [
                        _vm._v("Submit Proteins"),
                      ]),
                    ]
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.modified && !_vm.cifcust
              ? _c("div", [
                  _c(
                    "form",
                    {
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.submitModifications.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("label", [
                        _vm._v("Select modified residues to highlight"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "selection-box",
                          style: {
                            backgroundColor: "white",
                            padding: "10px",
                            border: "1px solid #ddd",
                            marginBottom: "10px",
                            maxHeight: "100px",
                            overflowY: "auto",
                          },
                          attrs: { id: "modSelect" },
                        },
                        [
                          _c("div", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.selectAllModifiedChecked,
                                  expression: "selectAllModifiedChecked",
                                },
                              ],
                              attrs: {
                                type: "checkbox",
                                id: "selectAllModified",
                              },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.selectAllModifiedChecked
                                )
                                  ? _vm._i(_vm.selectAllModifiedChecked, null) >
                                    -1
                                  : _vm.selectAllModifiedChecked,
                              },
                              on: {
                                change: [
                                  function ($event) {
                                    var $$a = _vm.selectAllModifiedChecked,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.selectAllModifiedChecked =
                                            $$a.concat([$$v]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.selectAllModifiedChecked = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.selectAllModifiedChecked = $$c
                                    }
                                  },
                                  _vm.selectAllModifiedChanged,
                                ],
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              { attrs: { for: "selectAllModified" } },
                              [_vm._v("Select All")]
                            ),
                          ]),
                          _vm._v(" "),
                          _vm._l(
                            _vm.modified_residues.entries(),
                            function (ref) {
                              var text = ref[0]
                              var k = ref[1]
                              return _c("div", { key: k }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.selectedResidues,
                                      expression: "selectedResidues",
                                    },
                                  ],
                                  attrs: { type: "checkbox", id: text },
                                  domProps: {
                                    value: text,
                                    checked: Array.isArray(_vm.selectedResidues)
                                      ? _vm._i(_vm.selectedResidues, text) > -1
                                      : _vm.selectedResidues,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a = _vm.selectedResidues,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = text,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            (_vm.selectedResidues = $$a.concat([
                                              $$v,
                                            ]))
                                        } else {
                                          $$i > -1 &&
                                            (_vm.selectedResidues = $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1)))
                                        }
                                      } else {
                                        _vm.selectedResidues = $$c
                                      }
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("label", { attrs: { for: text } }, [
                                  _vm._v(_vm._s(text)),
                                ]),
                              ])
                            }
                          ),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c("button", { attrs: { type: "submit" } }, [
                        _vm._v("Submit Residues"),
                      ]),
                    ]
                  ),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "alignment_section" }, [
      _vm.alnobj
        ? _c("div", { attrs: { id: "alnif" } }, [
            _c(
              "div",
              { staticStyle: { display: "flex" }, attrs: { id: "alnMenu" } },
              [
                _vm.msavWillMount
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-outline-dark",
                        staticStyle: { margin: "0 1%" },
                        attrs: { id: "downloadFastaBtn", type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.downloadAlignmentData()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                    Download alignment\n                "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.cdHITReport
                  ? _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.cdhitSelectedOpt,
                            expression: "cdhitSelectedOpt",
                          },
                        ],
                        staticClass: "btn btn-outline-dark dropdown-toggle",
                        staticStyle: { margin: "0 1%" },
                        attrs: { id: "cdHITResults" },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.cdhitSelectedOpt = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                        },
                      },
                      [
                        _c(
                          "option",
                          {
                            attrs: { selected: "", disabled: "" },
                            domProps: { value: null },
                          },
                          [_vm._v("See cdhit options")]
                        ),
                        _vm._v(" "),
                        _vm._l(_vm.cdhitOpts, function (prop) {
                          return _c(
                            "option",
                            { key: prop.Name, domProps: { value: prop.value } },
                            [_vm._v(_vm._s(prop.Name))]
                          )
                        }),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.msavWillMount
                  ? _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.downloadAlignmentOpt,
                            expression: "downloadAlignmentOpt",
                          },
                        ],
                        staticClass: "btn btn-outline-dark dropdown-toggle",
                        staticStyle: { margin: "0 1%" },
                        attrs: { id: "downloadAlnImageBtn" },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.downloadAlignmentOpt = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                        },
                      },
                      [
                        _c(
                          "option",
                          {
                            attrs: { selected: "", disabled: "" },
                            domProps: { value: null },
                          },
                          [_vm._v("Download alignment image")]
                        ),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "full" } }, [
                          _vm._v("Full alignment"),
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "visible" } }, [
                          _vm._v("Visible alignment"),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.msavWillMount
                  ? _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selected_property,
                            expression: "selected_property",
                          },
                        ],
                        staticClass: "btn btn-outline-dark dropdown-toggle",
                        staticStyle: { margin: "0 1%" },
                        attrs: { id: "selectColorMappingProps" },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.selected_property = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                        },
                      },
                      [
                        _c(
                          "option",
                          {
                            attrs: { selected: "", disabled: "" },
                            domProps: { value: null },
                          },
                          [_vm._v("Select data")]
                        ),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "Clear data" } }, [
                          _vm._v("Clear data"),
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "Select data" } }, [
                          _vm._v("Contacts"),
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.available_properties, function (prop) {
                          return _c("option", { key: prop.Name }, [
                            _vm._v(_vm._s(prop.Name)),
                          ])
                        }),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.msavWillMount
                  ? _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.colorScheme,
                            expression: "colorScheme",
                          },
                        ],
                        staticClass: "btn btn-outline-dark dropdown-toggle",
                        staticStyle: { margin: "0 1%" },
                        attrs: { id: "selectAlnColorScheme" },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.colorScheme = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                        },
                      },
                      [
                        _c(
                          "option",
                          {
                            attrs: { selected: "", disabled: "" },
                            domProps: { value: null },
                          },
                          [_vm._v("Select a colorscheme")]
                        ),
                        _vm._v(" "),
                        _vm._l(_vm.availColorschemes, function (colorscheme) {
                          return _c("option", { key: colorscheme }, [
                            _vm._v(_vm._s(colorscheme)),
                          ])
                        }),
                      ],
                      2
                    )
                  : _vm._e(),
              ]
            ),
            _vm._v(" "),
            _vm._m(0),
          ])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "warningSection" }, [
      _vm.cdHITReport && _vm.didCDHit_truncate
        ? _c("div", { attrs: { id: "warningCDHITtruncation" } }, [
            _c("b", [
              _vm._v(
                "Warning, your alignment sequences were clustered by cdhit! See dropdown menu above the alignment for\n                options."
              ),
              _c("br"),
              _vm._v(
                "\n                Original alignment had " +
                  _vm._s(this.cdHITnums[0]) +
                  " sequences, which were clustered in\n                " +
                  _vm._s(this.cdHITnums[1]) +
                  " groups using threshold of 90% identity."
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.poor_structure_map && _vm.poor_structure_map >= 60
        ? _c("div", { attrs: { id: "warningPoorStructureAln" } }, [
            _c("b", [
              _vm._v(
                "Warning, poor alignment between selected MSA and structure!!!\n                Found " +
                  _vm._s(_vm.poor_structure_map) +
                  " poorly aligned residues. Consider selecting a new MSA/structure pair.\n            "
              ),
            ]),
          ])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "topology_section" }, [
      _vm.chainid.length > 0 || _vm.customPDBsuccess
        ? _c("span", { attrs: { id: "topif" } }, [
            !_vm.topology_loaded
              ? _c("div", [
                  _vm._v(
                    "\n                Wait for alignment-structure mapping "
                  ),
                  _c("img", {
                    staticStyle: { height: "25px" },
                    attrs: {
                      src: "static/img/loading.gif",
                      alt: "Loading topology viewer",
                    },
                  }),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { attrs: { id: "topview" } }),
          ])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _vm.topology_loaded
      ? _c(
          "div",
          { staticClass: "gradient_section" },
          _vm._l(_vm.available_properties, function (prop) {
            return _vm.selected_property == prop.Name
              ? _c("img", {
                  key: prop.Name,
                  attrs: { id: "gradientSVG", src: prop.url },
                })
              : _vm._e()
          }),
          0
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "molstar_section" }, [
      _vm.PDBparsing == true
        ? _c("div", [
            _vm._v("\n            Parsing PDB structure "),
            _c("img", {
              staticStyle: { height: "25px" },
              attrs: {
                src: "static/img/loading.gif",
                alt: "Parsing PDB structure",
              },
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.PDBparsing == "error"
        ? _c("div", [
            _vm._v(
              "\n            Failed to parse the PDB structure! Try a different structure.\n        "
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.chainid.length > 0 || _vm.customPDBsuccess
        ? _c("span", { attrs: { id: "molif" } }, [_vm._m(1)])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _vm._m(2),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { id: "alnDiv" } }, [
      _vm._v("Loading alignment "),
      _c("img", {
        staticStyle: { height: "25px" },
        attrs: { src: "static/img/loading.gif", alt: "Loading alignment" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { id: "pdbeMolstarView" } }, [
      _vm._v("\n                Loading Molstar Component "),
      _c("img", {
        staticStyle: { height: "25px" },
        attrs: { src: "static/img/loading.gif", alt: "Loading MolStar" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("footer", [
      _c("div", {
        staticStyle: { display: "flex" },
        attrs: { id: "footerDiv" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }